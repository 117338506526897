import {
    AppBar, Toolbar, Typography, Box, IconButton, Button,
    Card, CardHeader, CardContent, List, ListItem
} from '@mui/material';



function Home() {


    return (
         <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static">
                  <Toolbar>
                      

                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>

                  </Toolbar>
              </AppBar>

              <Card>
                  <CardHeader title="Інструкція з використання" />

                  <CardContent>
                      <Typography>
                          Створено на платформі <a href="https://arenasport.training">Arena.Sport</a>. Дізнатись більше можна за посиланням
                          <a href="https://arenasport.training">https://arenasport.training</a>
                      </Typography><br />
                      <Typography>

                      </Typography>
                  </CardContent>
              </Card>

        </Box>
    );
}

export default Home;
