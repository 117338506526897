import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Calendar from './pages/calendar'
import Home from './pages/home'
import {store, persistor} from './redux/store'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const themeParams = window.Telegram.WebApp.themeParams;

window.addEventListener("beforeunload", function() {
    window.Telegram.WebApp.sendData("Web App is closing...");
});

window.Telegram.WebApp.onEvent('popupClosed', () => {
    window.Telegram.WebApp.sendData("POPUP CLOSED...");
})

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/calendar",
        element: <Calendar />,
    },
]);

const theme = createTheme({
  palette: {
    mode: 'dark',//themeParams?.theme === 'dark' ? 'dark' : 'light', // Устанавливаем режим темы на основе параметров Telegram
    /*
    background: {
      default: themeParams?.bg_color || '#ffffff', // Фон
    },
    text: {
      primary: themeParams?.text_color || '#000000', // Основной цвет текста
    },
    primary: {
      main: themeParams?.button_color || '#1976d2', // Основной цвет для акцентов
    },
    */
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <RouterProvider router={router} />
                </PersistGate>
            </ReduxProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
