import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


export default function Calendar() {
    return (
        <Stack direction="column" justifyConten="center" alignItems="center">
            <ButtonGroup variant="contained">
                <Button>День</Button>
                <Button>Тиждень</Button>
                <Button>Місяць</Button>
            </ButtonGroup>
            <Paper elevation={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
            </LocalizationProvider>
            </Paper>
        </Stack>
    );
}
